.title{
	display: flex;
    flex-direction: column;
    align-items: center;
}

.title img{
	width: 160px;
	height: auto;
	margin-top:15px
}

.title h1{
    font-weight: 900;
    color: #1976d2;
    font-size: 64px;
    line-height: 1;
    
}

.container {
	background: #0f9bf6;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}
.content {
	text-align: center;
	font-size: 2em;
	color: rgba(255, 255, 255, 0.8);
	padding: 40px;
}

.content p {
	color: white;
    margin: 0 100px;
}

.waves {
	position: absolute;
	/*      left: 0;
      right: 0;
      height: 200px;
      bottom: 0;*/
	bottom: -135px;
	height: 527px;
	width: 100%;
	overflow: hidden;
}
.wave {
	position: absolute;
	left: -180px;
	
	/*width: 200px;*/
	/*height: 200px;*/
	/*width: 100%;*/
	/*border-radius: 50%;*/
	/*background:rgba(0,0,0, .3);*/
	width: 2402px;
	height: 365px;
	background: url(https://static.platzi.com/media/files/waves_c8551f5d-ecf6-4a81-ae1d-f3a0ad55ba10.png)
		center bottom no-repeat;
	animation: 5s wave ease-in-out infinite alternate;
}
.a {
	background-position: 0 -854px;
}
.b {
	background-position: 0 -427px;
	animation-delay: 0.6s;
}
.c {
	background-position: 0 0;
	animation-delay: 1.2s;
}
/*.container {
      position: absolute;
    }*/
@keyframes wave {
	0% {
		transform: translate(0, 0);
	}
	50% {
		transform: translate(-80px, 30px);
	}
	100% {
		transform: translate(160px, -60px);
	}
}

@media (max-width:720px){
	.content{
		font-size:large;
		padding: 5px;
	}

	.content p {
		margin: 0 15px;
	}

	.title h1{
		font-size: 52px;
	}

	.title img{
		width: 100px;
		height: auto;
	}
}
