.title{
    font-weight: 900;
    color: #1976d2;
    font-size: 64px;
    line-height: 1;
    display: flex;
    justify-content: center;
    padding: 120px 0 30px; 
}

.formContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0f9bf6;
}

.form{
    width: 50%;
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    align-items: center;
}

.input {
    width: 100%;
  }

  .errors {
    color: red;
    height: 20px;
  }

  @media(max-width:720px){
    .title{
        font-size: 52px;
        padding: 50px 0 30px;
    }
    .form{
        width: 85%;
    }
  }