.container{
    background-color: #dddada8c;
    display: flex;
    justify-content: space-around;
}

.img{
    display: flex;
    align-items: center;
    
}

.img img{
    width: 120px;
    height: 120px;
    margin: 10px 0;
}

.contentF{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contentF span{
    margin:7px 0;
    display: flex;
    align-items: center;
}

.contentF span p{
    margin-left: 10px;
}

.contentF span a{
    color: #0f9bf6;
}

.copy{
    display: flex;
    justify-content: center;
}

.copy a{
    color:#0f9bf6;
}
.contactIcons {
    display: flex;
    flex-direction: row;
    /* Alinear los iconos en fila */
    flex-wrap: wrap;
    /* Permitir que los elementos se ajusten a la siguiente línea */
    justify-content: space-around;
    /* Espaciar los iconos uniformemente */
    margin-top: 10px;
    /* Espaciado superior opcional */
}

.contactIcons span {
    display: flex;
    flex-direction: column;
    /* Mantener el icono y el texto en columna */
    align-items: center;
    /* Centrar los elementos */
    margin: 10px;
    /* Espacio entre los iconos */
}

@media (max-width: 720px){
    .container{
        flex-direction: column;
        align-items: center;
    }

    .img img{
        width: 100px;
        height: 100px;
    }
}
