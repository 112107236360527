.buttons{
    display: flex;
    width: 100%;
    justify-content: space-around;
    
}

.img{
  width: 60px;
  height: auto;
}

a:link,
a:visited,
a:active {
  text-decoration: none;
  color:white
}

@media (max-width:720px){
  .buttons a {
    font-size: small;
  }
}